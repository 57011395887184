<template>
  <div
    class="form-item grid"
    :class="{ required: required, disabled: disabled, error: isError }"
    :data-success="isSuccess"
  >
    <label v-if="label" class="form-item__label">
      {{ label + (minlength > 0 ? ` (минимум ${minlength} символов)` : '') }}
    </label>
    <input
      v-if="separator"
      type="number"
      pattern="\d*"
      :name="'form-item-' + uid"
      class="form-item__input pl-10 pr-10"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      v-model="propValue"
    />
    <input
      v-else-if="type === 'number' && maxlength > 0"
      :type="text"
      pattern="[0-9]"
      :name="'form-item-' + uid"
      class="form-item__input pl-10 pr-10"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :maxlength="maxlength"
      v-model="propValue"
    />
    <input
      v-else
      :type="type"
      :name="'form-item-' + uid"
      class="form-item__input pl-10 pr-10"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :maxlength="maxlength"
      v-model="propValue"
    />
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, computed } from 'vue'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: '-'
  },
  maxlength: {
    type: Number,
    default: -1
  },
  minlength: {
    type: Number,
    default: -1
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  },
  separator: {
    type: Boolean,
    default: false
  }
})

// Data
const { label, type, placeholder, required, modelValue, separator, minlength } = toRefs(props)
const uid = getCurrentInstance().uid

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => {
    let str = val.trim()
    str = DOMPurify.sanitize(str)
    emits('update:modelValue', str)
  }
})

const isSuccess = computed(() => {
  let approve = minlength.value > 0 ? propValue.value.length >= minlength.value : true
  return !!propValue.value && approve
})
</script>

<style lang="scss" scoped></style>
