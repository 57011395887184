import axios from '../axios'

class NotificationsSettingsApi {
  get() {
    return axios.get(`/api/user-notification`)
  }
  update(payload) {
    return axios.post(`/api/user-notification`, payload)
  }
  addCompany(payload) {
    return axios.post('/api/company', payload)
  }
  getCompany(id) {
    return axios.get(`/api/company/${id}`)
  }
  updateCompany(id, payload) {
    return axios.put(`/api/company/${id}`, payload)
  }
  updateSettings(id, payload) {
    return axios.post(`/api/company/${id}`, payload)
  }
}

export default new NotificationsSettingsApi()
