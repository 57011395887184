import axios from '../axios'

class EventsApi {
  getAll(id, s, types) {
    let url = `/api/financier-application/history/${id}`
    if (s) url = `${url}?s_comment=${s}`
    if (types) url = `${url}${s ? '&' : '?'}types[]=${types}`
    return axios.get(url)
  }
  get(orderId, id) {
    return axios.get(`/api/financier-application/${orderId}/events/${id}`)
  }
  update(id, payload) {
    return axios.patch(`/api/financier-application/history/${id}`, payload)
  }
  comment(payload) {
    return axios.post(`/api/financier-application/history`, payload)
  }
}

export default new EventsApi()
