<template>
  <div class="base-toolbar">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.base-toolbar {
  background-color: var(--toolbar);
  border-bottom: var(--b1);
  height: 50px;
  position: relative;
}
</style>
