<template>
  <div
    class="form-item grid datepicker"
    :class="{ required: required, disabled: disabled, error: isError }"
    :data-success="isSuccess"
  >
    <label v-if="label" class="form-item__label">
      {{ label }}
    </label>
    <DatePicker
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :lang="langObject"
      :append-to-body="false"
      :disabled-date="disabledDate"
      :type="type"
      :value-type="format"
      :minute-options="minuteOptions"
      :hour-options="hourOptions"
      :class="{ clearable: clearable }"
      :format="format"
      v-model:value="propValue"
    />
  </div>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ru'
import { defineProps, defineEmits, toRefs, computed } from 'vue'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  type: {
    type: String,
    default: 'date'
  },
  format: {
    type: String,
    default: 'DD-MM-YYYY HH:mm'
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Выберите дату'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [String, Date],
    default: ''
  },
  showPastDays: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  startDate: {
    type: String,
    default: ''
  },
  endDate: {
    type: String,
    default: ''
  }
})

// Data
const { label, placeholder, required, modelValue, showPastDays, clearable, startDate, endDate } = toRefs(props)
const langObject = {
  formatLocale: {
    firstDayOfWeek: 1
  },
  monthBeforeYear: false
}
// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const isSuccess = computed(() => {
  return !!propValue.value
})

// Methods
function disabledDate(d) {
  const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
  const date = new Date(new Date(d).setHours(0, 0, 0, 0)).getTime()
  const start = startDate?.value && new Date(new Date(startDate?.value).setHours(0, 0, 0, 0)).getTime()
  const end = endDate?.value && new Date(new Date(endDate?.value).setHours(0, 0, 0, 0)).getTime()

  if (showPastDays.value) {
    return !(date <= today || date >= today)
  }

  if (startDate.value && !endDate.value) {
    return !(date >= start - 1)
  }

  if (startDate.value && endDate.value) {
    return !(date >= start - 1 && date <= end)
  }

  if (!startDate.value && endDate.value) {
    return !(date <= today - 1 && date <= end)
  }

  return date <= today - 1
}
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;
}
.mx-input {
  background-color: var(--form);
  box-shadow: inset 0 0 0 1px var(--bcolor);
  border-radius: var(--br);
  color: var(--text);
  border: 0;
  min-height: 36px;
  width: 100%;

  &:hover {
    box-shadow: inset 0 0 0 2px var(--bcolor);
  }

  &:focus,
  &.focused {
    box-shadow: inset 0 0 0 2px var(--primary);
  }
}
.datepicker.error .mx-input {
  box-shadow: inset 0 0 0 2px var(--red);
}

.mx-icon-calendar,
.mx-icon-clear {
  color: #6f7985;
}
.mx-icon-clear:hover {
  color: var(--text);
}
.clearable .mx-icon-clear {
  display: none !important;
}
.clearable .mx-icon-calendar {
  display: block !important;
}
</style>
