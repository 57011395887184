<template>
  <form class="base-head-search" v-click-outside="closeAction">
    <label for="s" class="base-head-search__label flex ai-c jc-c">
      <BaseLoad v-if="isLoad" class="rel sm" />
      <BaseIcon v-else class="ic-16 grey" icon="search" />
    </label>
    <input
      type="search"
      name="s"
      id="s"
      class="base-head-search__input"
      placeholder="Номер заявки"
      autocomplete="off"
      :data-btn="uniqId"
      v-model="searchText"
      @click="toggleAction"
    />

    <div
      v-if="isOpen && optionsOrders && optionsOrders.length"
      class="base-head-search__body box p-5 overflow-a grid z2"
      :data-body="uniqId"
    >
      <!-- <BaseLoad v-if="isLoad" class="white-transparent" /> -->
      <button
        v-for="item of optionsOrders"
        :key="item.value"
        class="btn sm transparent-primary"
        @click.prevent="openOrder(item.value)"
      >
        {{ item.text }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { debounce } from 'lodash'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, computed, watch } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'
import { calcCoords } from '@/plugins'

// Data
const store = useStore()
const router = useRouter()
const isLoad = ref(false)
const isOpen = ref(false)
const searchText = ref('')
const uniqId = 'ddown-search'
// const direction = ''

// Computed
const optionsOrders = computed(() => store.getters['helpers/orders'] || [])

// Watch
watch(searchText, debounce(searchOrder, 1000))

// Methods
async function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId)
}
function closeAction() {
  isOpen.value = false
}
function openOrder(id) {
  isOpen.value = false
  router.push('/orders/view/' + id)
}
async function searchOrder() {
  isLoad.value = true
  await store.dispatch('helpers/SEARCH_ORDERS', searchText.value)
  isOpen.value = true
  isLoad.value = false
}
</script>

<style lang="scss" scoped>
.base-head-search {
  position: relative;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
  }

  &__input {
    border: 0;
    border-radius: var(--br);
    background-color: var(--box);
    height: 34px;
    padding: 0 10px 0 40px;

    &:hover {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--secondary);
    }
  }

  &__body {
    position: fixed;
    z-index: 2001;
    box-shadow: var(--box-shadow);
    max-height: calc(100vh - 300px);
    min-height: 100px;
  }
}
</style>
