<template>
  <table class="table-native mb-40 no-select">
    <thead :class="{ empty: !rows || !rows.length }">
      <template v-for="col of cols" :key="col.key">
        <th v-if="col.show && !col.disabled" :style="{ minWidth: col.width }">
          <div class="grid gtc-1-auto ai-c ggap-5 cut">
            <div class="grid gtc-auto-1 ai-c ggap-10">
              <button
                v-if="isGrouped"
                class="table-native__all btn outline sm rounded cube-20"
                @click="emits('toggleAll')"
              >
                <BaseIcon class="ic-16 primary" :icon="isOpenAll ? 'arrow-down' : 'arrow-right'" />
              </button>
              <span class="cut" :title="col.name">
                {{ col.name }}
              </span>
            </div>
            <slot name="th" :col="col" />
          </div>
        </th>
      </template>
    </thead>

    <tbody>
      <!-- Rows -->
      <template v-if="rows && rows.length">
        <!-- Grouped row -->
        <template v-if="isGrouped">
          <template v-for="group of groupedRows" :key="group.id">
            <!-- Head group -->
            <tr @click="toggleGroupOpened(group)" class="table-native__group" :class="{ opened: getOpened(group) }">
              <td :colspan="colspanLength">
                <div class="flex ai-c ggap-10 pl-10 pr-10">
                  <button class="btn sm rounded cube-20" :class="group.opened ? 'primary' : 'grey'">
                    <BaseIcon
                      class="ic-16"
                      :class="group.opened ? 'white' : 'black'"
                      :icon="group.opened ? 'arrow-down' : 'arrow-right'"
                    />
                  </button>

                  <div class="flex ai-c ggap-10">
                    <div class="t-grey-dark">Сгруппировано:</div>

                    <!-- Status -->
                    <small
                      v-if="group.groupedKey === 'status' && group.groupedItem"
                      class="marker cut"
                      :style="{
                        color: group.groupedItem.text_color,
                        backgroundColor: group.groupedItem.background_color
                      }"
                    >
                      {{ group.groupedValue }}
                    </small>

                    <!-- Author -->
                    <template v-else-if="group.groupedKey === 'author'">
                      <template v-if="group.groupedItem && profile.id === group.groupedItem.id">
                        <small class="marker primary">Вы</small>
                      </template>
                      <template v-else>
                        <a v-if="group.groupedItem && group.groupedItem.id" :href="profileLink + group.groupedItem.id">
                          {{ group.groupedValue }}
                        </a>
                        <b v-else>Не известный инициатор</b>
                      </template>
                    </template>

                    <!-- Counterparty -->
                    <template v-else-if="group.groupedKey === 'counterparty'">
                      <div v-if="group.groupedItem && group.groupedItem.id">
                        {{ group.groupedValue || 'Контрагент без наименования' }}
                      </div>
                      <b v-else>Контрагент не указан</b>
                    </template>

                    <!-- Project -->
                    <template v-else-if="group.groupedKey === 'company'">
                      <div v-if="group.groupedItem && group.groupedItem.id">
                        {{ group.groupedValue || 'Проект без наименования' }}
                      </div>
                      <b v-else>Проект не указан</b>
                    </template>

                    <!-- Other -->
                    <div v-else>{{ group.groupedValue || 'Не известно' }}</div>

                    <!-- Counter -->
                    <small class="marker white">{{ group.subrows.length }}</small>
                  </div>
                </div>
              </td>
            </tr>

            <!-- Rows group -->
            <template v-if="group.opened">
              <tr
                v-for="row of group.subrows"
                :key="row.id"
                class="table-native__row"
                :class="{ visited: visited === row.id }"
              >
                <template v-for="col of cols" :key="col.key">
                  <td @click="openRow(row, col)" v-if="col.show && !col.disabled" :style="{ maxWidth: col.width }">
                    <slot name="td" :col="col" :row="row">
                      <div class="grid cut">
                        <span class="cut">
                          {{ typeof row[col.key] === 'object' ? '-' : row[col.key] }}
                        </span>
                      </div>
                    </slot>
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </template>

        <!-- Row -->
        <template v-else>
          <tr v-for="row of rows" :key="row.id" class="table-native__row" :class="{ visited: visited === row.id }">
            <template v-for="col of cols" :key="col.key">
              <td
                v-if="col.show && !col.disabled"
                :style="{ maxWidth: col.width }"
                @click="!col.clickDisabled && $router.push('/orders/view/' + row.id)"
              >
                <slot name="td" :col="col" :row="row">
                  <div class="grid cut">
                    <span class="cut">
                      {{ typeof row[col.key] === 'object' ? '-' : row[col.key] }}
                    </span>
                  </div>
                </slot>
              </td>
            </template>
          </tr>
        </template>
      </template>

      <!-- Alert -->
      <tr v-else class="empty">
        <td :colspan="colspanLength">
          <BaseAlert type="yellow">Ничего не найдено</BaseAlert>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, computed, defineProps, toRefs, inject } from 'vue'
import { BaseIcon, BaseAlert } from '@/components'
import { useRouter } from 'vue-router'

// Emits
const emits = defineEmits(['toggleAll'])

// Props
const props = defineProps({
  cols: {
    type: Array,
    default: () => []
  },
  rows: {
    type: Array,
    default: () => []
  },
  groupedRows: {
    type: Array,
    default: () => []
  },
  isGrouped: {
    type: Boolean,
    default: false
  },
  isOpenAll: {
    type: Boolean,
    default: false
  },
  visited: {
    type: Number,
    default: 0
  }
})

// Data
const { cols, rows, groupedRows, isGrouped, isOpenAll, visited } = toRefs(props)
const store = useStore()
const router = useRouter()
const profileLink = inject('profileLink')

// Computed
const colspanLength = computed(() => cols.value.filter((el) => el.show && !el.disabled)?.length)
const profile = computed(() => store.getters.profile)

// Methods
function toggleGroupOpened(group) {
  const groupedRowsLocal = JSON.parse(localStorage.getItem('grouped-rows')) || {}
  const index = groupedRowsLocal?.[group.groupedKey]?.findIndex((el) => el.key === group.groupedValue)
  if (index > -1) {
    groupedRowsLocal[group.groupedKey][index] = { key: group.groupedValue, opened: !group.opened }
  } else {
    if (!groupedRowsLocal[group.groupedKey]?.length) groupedRowsLocal[group.groupedKey] = []
    groupedRowsLocal[group.groupedKey].push({ key: group.groupedValue, opened: !group.opened })
  }
  group.opened = !group.opened
  localStorage.setItem('grouped-rows', JSON.stringify(groupedRowsLocal))
}

function getOpened(group) {
  const groupedRowsLocal = JSON.parse(localStorage.getItem('grouped-rows'))
  return groupedRowsLocal?.[group.groupedKey]?.find((el) => el.key === group.groupedValue)?.opened ?? true
}

function openRow(row, col) {
  if (!col.clickDisabled) {
    router.push('/orders/view/' + row.id)
  }
}
</script>

<style lang="scss">
.table-native {
  padding: 0;
  border-collapse: collapse;
  width: 100%;
  $parent: &;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: left;
    box-shadow: 0 1px 0 0 var(--bcolor), 2px 0 0 0 var(--bg), -2px 0 0 0 var(--bg);

    &.empty {
      box-shadow: none;
    }

    th {
      background-color: var(--bg);
      padding: 10px;
      font-size: 12px;

      #{$parent}__all {
        display: none;
      }

      &:first-child #{$parent}__all {
        display: flex;
      }
    }
  }

  &__group {
    background-color: var(--box);
    border: var(--b1);
    height: 40px;

    &:first-child {
      border-top: 0;
    }

    &:hover {
      background-color: var(--primary-el);
    }

    &.opened {
      background-color: var(--primary-el);
    }
  }

  &__row {
    background-color: var(--box);
    cursor: pointer;

    &:hover {
      background-color: var(--grey-el);
    }
    &:active {
      background-color: var(--grey);
    }
    &.visited {
      box-shadow: inset 4px 0 0 0 var(--primary);
    }

    & > td:first-child {
      border-left: var(--b1);
    }

    td {
      border-bottom: var(--b1);
      border-right: var(--b1);
      padding: 8px 10px;
      vertical-align: middle;
    }
  }
}
</style>
