import orders from '@/api/modules/orders.js'

export default {
  namespaced: true,
  state: () => ({
    statuses: [],
    departments: [],
    orders: []
  }),
  mutations: {
    SET_STATUSES(state, payload) {
      state.statuses = payload
    },
    SET_DEPARTMENTS(state, payload) {
      state.departments = payload.reverse()
    },
    SET_ORDERS(state, payload) {
      state.orders = payload?.length
        ? payload.map((el) => {
            return {
              text: el.id + ' - ' + el?.company?.project,
              value: el.id
            }
          })
        : []
    }
  },
  actions: {
    async GET_STATUSES({ commit }) {
      try {
        const { data } = await orders.getStatuses()
        commit('SET_STATUSES', data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async SEARCH_ORDERS({ commit }, payload) {
      try {
        const { data } = await orders.search(payload)
        commit('SET_ORDERS', data.data)
      } catch (e) {
        console.log(e)
      }
    }
  },
  getters: {
    statuses: (s) => s.statuses,
    departments: (s) => s.departments,
    orders: (s) => s.orders
  }
}
