import axios from '../axios'
import moment from 'moment'

class OrdersApi {
  getAll(options, sort, size, page) {
    const archive = JSON.parse(localStorage?.getItem('archiveShow'))
    let url = `/api/financier-application?page=${page || 1}&size=${size || 10}`
    url += `&is_archive=${Number(archive)}`
    url += options.type ? `&type=${options.type}` : ''
    url += options.pay_date_planned
      ? `&pay_date_planned=${moment(options.pay_date_planned, 'DD-MM-YYYY').format('YYYY-MM-DD')}`
      : ''
    url += options.pay_date ? `&pay_date=${moment(options.pay_date, 'DD-MM-YYYY').format('YYYY-MM-DD')}` : ''
    url += options.cost ? `&cost=${options.cost}` : ''
    url += options?.author?.id ? `&author_id=${options.author.id}` : ''
    url += options?.currency ? `&currency=${options.currency}` : ''
    url += options.counterparty ? `&counterparty_id[]=${options.counterparty?.id}` : ''
    if (options.company?.length)
      for (let i = 0; i < options.company.length; i++) url += `&project_id[]=${options.company[i]}`
    if (options.status?.length)
      for (let i = 0; i < options.status.length; i++) url += `&status_id[]=${options.status[i]}`
    if (sort?.key) url += sort?.direction === 'asc' ? `&sort[]=${sort.key}` : `&sort_desc[]=${sort.key}`
    return axios.get(url)
  }
  downloadExcel(options, size, page, start, end) {
    let url = `/api/financier-application/download-excel?page=1&size=${page * size}&start_date=${start}&end_date=${end}`
    url += options.type ? `&type=${options.type}` : ''
    url += options.pay_date_planned
      ? `&pay_date_planned=${moment(options.pay_date_planned, 'DD-MM-YYYY').format('YYYY-MM-DD')}`
      : ''
    url += options.pay_date ? `&pay_date=${moment(options.pay_date, 'DD-MM-YYYY').format('YYYY-MM-DD')}` : ''
    url += options.cost ? `&cost=${options.cost}` : ''
    url += options?.author?.id ? `&author_id=${options.author.id}` : ''
    url += options?.currency ? `&currency=${options.currency}` : ''
    if (options.company?.length)
      for (let i = 0; i < options.company.length; i++) url += `&project_id[]=${options.company[i]}`
    if (options.counterparty?.length)
      for (let i = 0; i < options.counterparty.length; i++) url += `&counterparty_id[]=${options.counterparty[i]}`
    if (options.status?.length)
      for (let i = 0; i < options.status.length; i++) url += `&status_id[]=${options.status[i]}`
    return axios.get(url)
  }
  getKbe() {
    return axios.get('api/financier-application/kbe')
  }
  updateStatus(id, payload) {
    console.log(id, payload)
    return axios.post(`api/financier-application/update-status/${id}`, payload)
  }
  getKnp() {
    return axios.get('api/financier-application/knp')
  }
  getStatuses() {
    return axios.get('/api/financier-application/statuses')
  }
  getTypes() {
    return axios.get('/api/financier-application/types')
  }
  search(id) {
    return axios.get(`/api/financier-application/search?s=${id}`)
  }
  get(id) {
    return axios.get(`/api/financier-application/${id}`)
  }
  save(payload) {
    return axios.post('/api/financier-application', payload)
  }
  create(payload) {
    return axios.post('/api/financier-application', payload)
  }
  draft(payload) {
    return axios.post('/api/financier-application/draft', payload)
  }
  updateDraft(id, payload) {
    return axios.post(`/api/financier-application/draft/${id}`, payload)
  }
  update(id, payload) {
    return axios.post(`/api/financier-application/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/financier-application/${id}`)
  }
  setApprover(application_id, user_id) {
    return axios.post(`/api/financier-application/history/add-participant`, { application_id, user_id })
  }
  findByTitle(title) {
    return axios.get(`/orders?title=${title}`)
  }
  approve(id, payload) {
    return axios.post(`api/financier-application/${id}/agreement`, payload)
  }
  approveAll(payload) {
    return axios.post(`api/financier-application/agreements`, payload)
  }
  reapprove(id, payload) {
    return axios.post(`api/financier-application/${id}/reagreement`, payload)
  }
}

export default new OrdersApi()
